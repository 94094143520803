import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { useRedirect } from '../../hooks/useRedirect'
import { useNoValidate } from '../../hooks/useNoValidate'
import {
  CheckoutContext,
  sendDemand,
  DEMAND_STATUS,
} from '../../components/context/CheckoutContext'
import { breakpoints, colors } from '../../theme'
import SalesPictureTitle from '../../components/SalesPictureTitle'
import { TextInput, Button, Pulse } from '@monbanquet/crumble'
import { navigate } from '../../components/Link'
import * as yup from 'yup'
import { useFormalWithProps } from '../../hooks/useFormalWithProps'
import DemandeLayout from '../../components/DemandeLayout'
import { EventContext } from '../../components/context/EventContext'
import { PhoneContext } from '../../components/context/PhoneContext'
import { CustomerContext } from '../../components/context/CustomerContext'
import { buildAskForQuoteBody, computeNextPageWithBaseUri } from '../../util'

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Pourriez-vous vérifier que l'email est correct ?")
    .required('Indiquez une adresse mail'),
})

const EmailPage = ({ location: { state } }) => {
  const redirect = useRedirect(!state || !state.next, '/demande/format')
  const { customer } = useContext(CustomerContext)

  const {
    state: { contactEmail },
    state: checkoutState,
    dispatch: dispatchCheckoutCtx,
  } = useContext(CheckoutContext)

  const {
    state: { eventFraming },
    state: eventState,
    categories,
    dispatch: dispatchEventCtx,
  } = useContext(EventContext)

  const {
    state: { e164 },
  } = useContext(PhoneContext)

  const noValidate = useNoValidate()
  const [error, setError] = useState()

  const submitContactForm = async () => {
    const nextUrl = computeNextPageWithBaseUri('demande', 'email', {
      isUserLoggedIn: !!customer,
      customer,
    })
    const body = buildAskForQuoteBody({
      ...checkoutState,
      ...eventState,
      categories,
    })
    const { status } = await sendDemand(dispatchCheckoutCtx, body)

    if (status) {
      navigate(nextUrl, { state: { next: true } })
    }
  }

  const { formal, emailProps } = useFormalWithProps(
    {
      email: contactEmail,
    },
    {
      schema,
      onSubmit: submitContactForm,
    },
  )

  return (
    <StyledEmailPage>
      <DemandeLayout>
        {redirect ? null : (
          <div>
            <SalesPictureTitle
              title={`C’est noté ! J’ai besoin d’une adresse mail pour vous faire parvenir le devis`}
            />
            <form
              noValidate={noValidate}
              onSubmit={async e => {
                e.preventDefault()
                try {
                  await formal.validate()
                  await formal.submit()
                } catch (err) {
                  console.info(err || formal.errors)
                }
              }}
            >
              <TextInput
                type="email"
                autoComplete="email"
                secondary
                placeholder={
                  eventFraming === 'b2b' ? `mon@email.pro` : `mon@email.com`
                }
                required
                autoFocus
                {...emailProps}
                onChange={ce => {
                  formal.change('email', ce)
                  dispatchCheckoutCtx({
                    type: 'UPDATE',
                    payload: {
                      contactEmail: ce,
                    },
                  })
                }}
              />
              <p className="error">{error}</p>
              <div className="btn-bar">
                <Button id="previous" onClick={() => navigate(-1)}>
                  Précédent
                </Button>
                <Button
                  id="next"
                  disabled={formal.isSubmitting}
                  type="submit"
                  className="send-order"
                >
                  {formal.isSubmitting ? (
                    <Pulse />
                  ) : (
                    <span>Suivant &rarr;</span>
                  )}
                </Button>
              </div>
            </form>
          </div>
        )}
      </DemandeLayout>
    </StyledEmailPage>
  )
}

const StyledEmailPage = styled.div`
  .page-content {
    max-width: 500px;
  }

  form {
    margin-top: 40px;
    & > * {
      margin-bottom: 20px;
    }
    > .error {
      margin-top: 10px;
      color: ${colors.brand.dark2};
    }
  }

  .names {
    display: flex;
    > * {
      flex: 1 1 0;
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  @media (max-width: ${breakpoints.mobile}px) {
    .dates,
    .names {
      > *:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
`

export default EmailPage
export { StyledEmailPage }
